/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import ErrorPage from "./modules/Errors/ErrorPage";
const Pages = lazy(() => import("./modules/Pages/Pages"));

export function Routes() {
  return (
    <Switch>
      <Redirect exact from="/" to="/subscription" />
      <Route exact path="/subscription" component={Pages} />
      <Route path="/error" component={ErrorPage} />

      <Redirect to="/error" />
    </Switch>
  );
}
